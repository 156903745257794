import { fetcher } from '../fetcher';

const TERMS_ENDPOINT = '/terms';

export const termsService = {
  getTerms: async () => {
    return await fetcher(`${TERMS_ENDPOINT}`, {
      method: 'GET',
      schema: termsSchema,
    });
  },
  acceptTerms: async (payload: Number[]) => {
    return await fetcher(`${TERMS_ENDPOINT}`, {
      method: 'POST',
      body: {
        term_version_ids: payload,
      },
    });
  },
  getGuestTerms: async () => {
    return await fetcher(`/guest-terms`, {
      method: 'GET',
      schema: guestTermsSchema,
    });
  },
};
